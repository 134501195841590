<!-- 客户报价 -->
<template>
  <div class="newCus-container">
    <div class="header"><span>客户报价</span></div>
    <div style="display: flex;">
      <el-form ref="form" :model="form" :rules="rules" label-width="120px">
        <el-form-item label="客户名称:" prop="name">
          <el-input
            class="name-ipt"
            placeholder="请输入（可搜索历史客户）"
            v-model.trim="form.name"
            clearable
            @change="
              () => {
                getCustList();
              }
            "
          ></el-input>
          <el-button
            @click="getCustList"
            class="common-screen-btn"
            type="primary"
            >查询</el-button
          >
          <el-button @click="clearIpt" class="common-screen-btn"
            >重置</el-button
          >

          <!-- <el-select
            class="add-ipt"
            @change="selectChange"
            @clear="selectClear"
            v-model="form.name"
            placeholder="请搜索客户"
            clearable
            filterable
            remote
            :remote-method="
              (val) => {
                remoteMethod(val, 'custList');
              }
            "
          >
            <el-option
              v-for="item in custList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select> -->
        </el-form-item>
        <el-form-item label="客户级别:" prop="level">
          <el-select
            v-if="custDict['1']"
            class="add-ipt"
            v-model="form.level"
            placeholder="请选择"
            clearable
          >
            <el-option
              v-for="item in custDict['1']"
              :label="item.name + ''"
              :value="item.id + ''"
              :key="item.id + ''"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属行业:" prop="industry">
          <el-select
            v-if="custDict['6']"
            v-model="form.industry"
            placeholder="请选择"
            class="add-ipt"
            clearable
          >
            <el-option
              v-for="item in custDict['6']"
              :label="item.name + ''"
              :value="item.id + ''"
              :key="item.id + ''"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="客户来源:" prop="source">
          <el-select
            v-if="custDict['2']"
            v-model="form.source"
            placeholder="请选择"
            class="add-ipt"
            clearable
          >
            <el-option
              v-for="item in custDict['2']"
              :label="item.name + ''"
              :value="item.id + ''"
              :key="item.id + ''"
            ></el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="电话:" prop="phone">
          <div v-for="(item, i) of domains" :key="i" class="delIcon">
            <el-input class="add-ipt" v-model="domains[i]" clearable></el-input>
            <i
              v-show="i == 0"
              class="el-icon-circle-plus-outline"
              @click="addDomain"
            ></i>
            <i
              v-show="i"
              class="el-icon-delete"
              @click="removeDomain(item)"
            ></i>
          </div>
        </el-form-item> -->
        <el-form-item v-for="(item, index) in form.phoneArr" :key="index" :prop="`phoneArr[${index}].value`" :rules="item.type == 'phone' ? rules.phoneArr : rules.wx">
            <template slot="label">
              <el-select v-model="item.type" style="width: 70%">
                <el-option label="手机" value="phone"></el-option>
                <el-option label="微信" value="wx"></el-option>
              </el-select>
            </template>
            <el-input class="add-ipt" v-model="item.value" maxLength="50" placeholder="请填写"></el-input>
            <span v-if="index == 0" class="el-icon-circle-plus-outline" @click="() => { addPhoneFun() }"></span>
            <span v-if="index != 0" class="el-icon-delete" @click="() => { deletePhoneFun(index) }"></span>
          </el-form-item>
        <!-- <el-form-item prop="teladd" v-show="domains.length > 1">
          <div class="plus">
            <el-input
              class="add-ipt"
              v-model.number="domain"
              clearable
            ></el-input>
            <i class="el-icon-delete" @click="removeDomain(item)"></i> -->
        <!-- <i class="el-icon-circle-plus-outline" @click="addDomain"></i> -->
        <!-- </div> -->
        <!-- </el-form-item> -->
        <!-- <el-form-item label="微信:" prop="wx">
          <el-input v-model="form.wx" class="add-ipt" clearable></el-input>
        </el-form-item> -->
        <el-form-item label="QQ:" prop="qq">
          <el-input
            v-model.number="form.qq"
            class="add-ipt"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="邮箱:" prop="mail">
          <el-input v-model="form.mail" class="add-ipt" clearable></el-input>
        </el-form-item>
        <el-form-item label="网址:" prop="web">
          <el-input v-model="form.web" class="add-ipt" clearable></el-input>
        </el-form-item>
        <el-form-item label="省市区" prop="areaId">
          <el-cascader
            v-if="cascaderFlag"
            :props="props"
            v-model="form.areaId"
            class="add-ipt"
            clearable
            placeholder="省市区"
          ></el-cascader>
          <!-- <el-cascader
            v-if="form.state"
            :props="props"
            v-model="form.areaId"
            class="add-ipt"
            clearable
            placeholder="详细地址，路名或街道名称，门牌号"
          ></el-cascader> -->
        </el-form-item>
        <el-form-item prop="address">
          <el-input
            v-model="form.address"
            placeholder="详细地址，路名或街道名称，门牌号"
            clearable
            class="add-ipt"
          ></el-input>
        </el-form-item>
        <el-form-item label="备注:" prop="note">
          <el-input
            type="textarea"
            :rows="2"
            placeholder="请输入内容"
            v-model="form.note"
            clearable
            class="add-ipt"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item>
          <el-button
            @click="
              () => {
                saveCustomer();
              }
            "
            type="primary"
            >保存</el-button
          >
        </el-form-item> -->
      </el-form>
      <div class="content" v-if="cusDetails.customerName">
        <div class="con-title">
          <div class="tit-left">
            <span class="name">{{ cusDetails.customerName || "--" }}</span
            ><span
              :class="{
                status: cusDetails.isDelete == 1,
                'status no-status': cusDetails.isDelete == 0
              }"
              >{{ cusDetails.isDelete == 0 ? "服务终止" : "正在服务" }}</span
            >
          </div>
        </div>
        <div class="con2">
          <div>
            <span class="mon-tit">累计付费 (元)</span>
            <span class="money">{{ cusDetails.collectionPrice || "--" }}</span>
          </div>
          <div>
            <span class="mon-tit">累计欠款 (元)</span>
            <span class="money">{{ cusDetails.historyPrice || "--" }}</span>
          </div>
          <div>
            <span class="mon-tit">正在服务店铺数 (家)</span>
            <span class="money">{{ cusDetails.shopNum || "--" }}</span>
          </div>
          <div>
            <span class="mon-tit">历史订单 (笔)</span>
            <span class="money">{{ cusDetails.orderNum || "--" }}</span>
          </div>
        </div>
        <div class="con3">
          <div class="base">基本信息</div>
          <div style="display: flex;">
            <div class="base-con ">
              <span>负责人：</span>
              <span>{{ cusDetails.chargeName || "--" }}</span>
            </div>
            <div class="base-con">
              <span>负责人所属部门：</span>
              <span>{{ cusDetails.departmentName || "--" }}</span>
            </div>
          </div>
          <div style="display: flex;" class="creat">
            <div class="base-con ">
              <span>创建人：</span>
              <span>{{ cusDetails.createName || "--" }}</span>
            </div>
            <div class="base-con">
              <span>创建时间: </span>
              <span>{{ cusDetails.customerCreateTime || "--" }}</span>
            </div>
          </div>
          <div style="display: flex;" class="creat">
            <div class="base-con ">
              <span>最后修改人:</span>
              <span>{{ cusDetails.updateName || "--" }}</span>
            </div>
            <div class="base-con">
              <span>最后修改时间：</span>
              <span>{{ cusDetails.customerUpdateTime || "--" }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="content con2 con4" v-else>
        <img src="~@/assets/images/null.png" alt="" />
        <span>当前为新客户暂无历史信息</span>
      </div>
    </div>
    <div class="gap3"></div>
    <div class="sixth">
      <el-button
        @click="
          () => {
            $router.push({ path: '/quoted/quotes' });
          }
        "
        plain
        class="sett-return"
        >返回报价中心</el-button
      >
      <el-button
        @click="
          () => {
            saveCustomer();
          }
        "
        type="primary"
        class="sett-con"
        >下一步：基础服务填报</el-button
      >
    </div>

    <!-- 弹框 -->
    <el-dialog
    v-dialogDrag
      title="查询结果"
      :visible.sync="dialogVisible"
      width="30%"
      :append-to-body="true"
    >
      <el-table @row-click="rowClick" :data="custList" style="width: 100%">
        <el-table-column prop="name" label="客户名称" :formatter="tableColumn">
        </el-table-column>
        <el-table-column
          prop="levelName"
          label="客户级别"
          :formatter="tableColumn"
        >
        </el-table-column>
        <el-table-column
          prop="industryName"
          label="所属行业"
          :formatter="tableColumn"
        >
        </el-table-column>
        <el-table-column
          prop="sourceName"
          label="客户来源"
          :formatter="tableColumn"
        >
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="(e) => pageFun(e, 'pageSize')"
        @current-change="(e) => pageFun(e)"
        style="text-align: center"
        :page-sizes="pagination.pageSizes"
        :page-size="params.pageSize"
        :current-page="params.pageNum"
        layout="total, prev, pager, next"
        :total="pagination.total"
      >
      </el-pagination>
    </el-dialog>
  </div>
</template>

<script>
//import引入的组件需要注入到components中才能使用
import { saveCus, offerCustomer, cusInfo } from "../../service/quoted.js";
import { commonDict, sysArea } from "../../service/common.js";
import { getFinishedList } from "../../service/customer";
import { isEmaill } from "../../utils/index.js";
import { tableColumn } from "../../utils/index.js";

export default {
  name: "NewCus",
  components: {},
  data() {
    let ruleAccount = (rule, value, callback) => {
      let reg = /^[1][1,3,5,7,8,9][0-9]{9}$/;
      if (value) {
        value = value.replace(/[\u202D+\u202C+\s+]/g, "");
        if (!reg.test(value)) {
          callback(new Error("手机号格式不正确!"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    return {
      tableColumn,
      form: {
        id: 0,
        customerId: 0,
        name: "",
        address: "",
        areaId: [],
        phoneArr: [{type: 'phone', value: ''}]
      },
      rules: {
        name: [{ required: true, message: "请输入内容", trigger: "blur" }],
        level: [{ required: true, message: "请输入内容", trigger: "blur" }],
        industry: [{ required: true, message: "请输入内容", trigger: "blur" }],
        source: [{ required: true, message: "请输入内容", trigger: "blur" }],
        phoneArr: [{ validator: ruleAccount, trigger: "change" },{ required: true, message: "请填写手机号", trigger: "change" }],
        wx: [{ required: true, message: "请填写微信号", trigger: "change" }],
        mail: [
          { validator: isEmaill, trigger: "blur", message: "请输入正确邮箱" }
        ]
      },
      domains: [""],
      domain: "",
      custDict: { "1": [], "2": [], "6": [] },
      props: {
        lazy: true,
        lazyLoad: async (node, resolve) => {
          const { level, value } = node;
          let typeId;
          if (level == 0) {
            typeId = 0;
          } else {
            typeId = value;
          }
          let resData = (await sysArea({ id: typeId })).data;
          let nodes = resData.map((item) => ({
            value: item.id + "",
            label: item.name,
            leaf: level >= 2
          }));
          resolve(nodes);
        }
      },
      custList: [], // 所属客户下拉
      baseId: this.$route.query.id,
      cusDetails: {},
      dialogVisible: false,
      pagination: {
        total: 0,
        pageSizes: [5, 10, 20, 50, 100]
      },
      params: {
        pageNum: 1,
        pageSize: 10
      },
      cascaderFlag: true,
      headerInfo: {},
      cusId: "" // 客户ID
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    // remoteMethod(val, name) {
    //   if (name == "custList") {
    //     this.getCustList(val);
    //   }
    // },
    addPhoneFun() { // 添加一条目联系方式
      this.form.phoneArr.push({type: 'phone', value: ''})
    },
    deletePhoneFun(i) { // 删除一条目联系方式
      this.form.phoneArr = this.form.phoneArr.filter((item, index) => {
        return index != i
      })
    },
    async getCustList() {
      // 所属客户下拉
      let params = { ...this.params };
      params.name = this.form.name;
      let { data } = await getFinishedList(params);
      this.pagination.total = data.total;
      this.pagination.pageSize = data.size;
      this.custList = data.records;
      if (this.custList.length) {
        this.dialogVisible = true;
      }else{
        this.$message.warning("暂无匹配的历史客户名称")
      }

      // if (data.records && data.records.length) {
      //   this.custList = data.records;
      // } else {
      //   this.form.customerId = 0;
      //   this.form.name = name;
      // }
    },
    clearIpt() {
      this.form = {};
      this.domains = [""];
      this.cusDetails = {};
    },
    // input 框后面删除
    removeDomain(item) {
      let index = this.domains.indexOf(item);
      if (this.domains.length == 1) {
        return;
      } else {
        if (index != -1) {
          this.domains.splice(index, 1);
          if (this.domains.length == 1) {
            this.domains.toString();
          }
        }
      }
    },
    addDomain() {
      this.domains.push(this.domain);
      this.domain = "";
    },
    selectClear() {
      this.form.customerId = "";
      this.form.name = "";
    },
    async saveCustomer() {
      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          let form = { ...this.form };
          // 处理地区
          if (form.areaId && form.areaId.length) {
            form.areaId = form.areaId.join(",");
          } else if (form.areaId && !form.areaId.length) {
            delete form.areaId;
          }
          // 电话
          // if (this.domains && this.domains.length) {
          //   let phone = [...this.domains];
          //   form.phone = phone.join(",");
          // }
          if (form.phoneArr && form.phoneArr.length) {
            let phone = []
            let wx = []
            form.phoneArr.map((item) => {
              if (item.type == 'phone') {
                phone.push(item.value)
              } else {
                wx.push(item.value)
              }
            })
            form.phone = phone.join(',')
            form.wx = wx.join(',')
            delete form.phoneArr
          }

          // 回显时 customerId 请求接口再给的
          if (!form.customerId && this.custList && this.custList.length) {
            form.customerId = this.custList[0].id;
          }
          // 回显
          if (this.$route.query.id) {
            form.id = this.$route.query.id;
          }

          
          const { data } = await saveCus(form);
          this.baseId = data.id;

          if (!data.id) {
            this.$message.error("保存失败");
          }
          this.$message.success("保存成功");

          if (this.baseId) {
            this.$router.push({
              path: "/quoted/base-services",
              query: { id: this.baseId, cusId: this.cusId }
            });
          } else {
            this.$message.error("请先保存表单");
          }

          // this.$refs["form"].resetFields();
          // this.domains = [];
        } else {
          this.$message.error("请填写必填项");
          return false;
        }
      });
    },
    async getDict(num) {
      const { data } = await commonDict({}, num);
      this.custDict[`${num}`] = data;
    },
    async getOfferCus(id) {
      const { data } = await offerCustomer({ id: id });
      this.cusDetails = data;
    },
    pageFun(e, type) {
      // 分页
      if (type == "pageSize") {
        this.params.pageSize = e;
      } else {
        this.params.pageNum = e;
      }
      this.getCustList();
    },
    rowClick(row) {
      console.log(row, "--------------------");
      this.cascaderFlag = false;
      row.industry = row.industry + "";
      row.source = row.source + "";
      // this.$set(this.form,'areaId',row.areaId.toString().split(","))
      // console.log(form);
      row.areaId = row.areaId.toString().split(",");
      let phoneArr = []
      if (row.phone) {
        row.phone.split(',').map((item) => {
          phoneArr.push({type: 'phone', value: item})
        })
      }
      if (row.wx) {
        row.wx.split(',').map((item) => {
          phoneArr.push({type: 'wx', value: item})
        })
      }
      row.phoneArr = phoneArr
      this.form = row;
      this.form.customerId = row.id;
      this.form.id = 0;
      console.log(this.form);
      this.domains = [];
      this.domains.push(row.phone);
      //       setTimeout(() => {
      // this.cascaderFlag = true
      //       }, 50)
      this.$nextTick(() => {
        this.cascaderFlag = true;
      });
      console.log(row, "-************");
      this.dialogVisible = false;
      this.cusId = row.customerId;
      this.getOfferCus(row.customerId);
    },
    async infoCus(id) {
      this.cascaderFlag = false;
      const { data } = await cusInfo({}, id);
      data.areaId = data.areaId.toString().split(",");
      data.industry = data.industry + "";
      data.source = data.source + "";
      let phoneArr = []
      if (data.phone) {
        data.phone.split(',').map((item) => {
          phoneArr.push({type: 'phone', value: item})
        })
      }
      if (data.wx) {
        data.wx.split(',').map((item) => {
          phoneArr.push({type: 'wx', value: item})
        })
      }
      data.phoneArr = phoneArr
      this.form = data;
      this.domains = [];
      this.domains.push(data.phone);
      this.$nextTick(() => {
        this.cascaderFlag = true;
      });
      let params = { ...this.params };
      params.name = this.form.name;
      let { data: res } = await getFinishedList(params);
      this.custList = res.records;
      this.getOfferCus(res.records[0].id);
    }
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    if (this.$route.query.id) {
      this.infoCus(this.$route.query.id);
    } else {
      this.form.id = 0;
    }
    this.getDict(1); //级别
    this.getDict(2); //来源
    this.getDict(6); //行业
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {}
};
</script>

<style lang="less" scoped>
.newCus-container {
  .header {
    height: 75px;
    background-color: #f6f9ff;
    text-align: left;
    border-bottom: 1px solid #b5d1ff;
    span {
      display: inline-block;
      font-size: 18px;
      margin-top: 28px;
      margin-left: 130px;
    }
  }
  .el-form::after {
    position: absolute;
    top: 70px;
    right: -70px;
    content: "";
    display: inline-block;
    width: 1px;
    height: 679px;
    background: #bbdfff;
  }
  .el-form {
    position: relative;
    width: 570px;
    margin-top: 32px;
    margin-left: 130px;
    .add-ipt {
      width: 400px;
    }
    .name-ipt {
      width: 260px;
    }
    .delIcon {
      position: relative;
    }
    .plus {
      position: relative;
    }
  }
  .el-icon-delete {
    font-size: 16px;
    margin-left: 8px;
    position: absolute;
    top: 13px;
  }
  .el-icon-circle-plus-outline, .el-icon-delete {
    color: #1890ff;
    font-size: 16px;
    margin-left: 8px;
    position: absolute;
    top: 13px;
  }
  .content {
    box-sizing: border-box;
    width: 749px;
    height: 451px;
    margin-top: 103px;
    margin-left: 140px;
    padding: 32px 32px 0;
    background: #f2f9ff;
    border: 1px solid rgba(24, 144, 255, 100);
    border-radius: 4px;
    .con-title {
      display: flex;
      justify-content: space-between;
      .tit-left {
        display: flex;
        align-items: center;
        .name {
          font-size: 28px;
        }
        .status {
          box-sizing: border-box;
          background-color: #f3ffeb;
          color: #65cc1e;
          border: 1px solid #65cc1e;
          border-radius: 2px;
          padding: 4px 6px;
          margin-left: 24px;
          font-size: 14px;
          line-height: 16px;
        }
        .no-status {
          background-color: #fff4f4;
          color: #f98787;
          border: 1px solid #f98787;
        }
      }
    }
    .con2 {
      display: flex;
      justify-content: space-between;
      margin-top: 39px;
      div {
        display: flex;
        flex-direction: column;
        .mon-tit {
          font-size: 13px;
          color: #666;
        }
        .money {
          font-size: 28px;
        }
        // &:nth-child(2) {
        //   margin-left: 68px;
        // }
      }
    }
    .con3 {
      .base {
        margin-top: 48px;
        margin-bottom: 24px;
        padding-bottom: 24px;
        border-bottom: 1px solid rgba(234, 234, 235, 100);
        color: rgba(16, 16, 16, 100);
        font-size: 14px;
        text-align: left;
      }
      .creat {
        margin-top: 24px;
      }
      .base-con {
        width: 310px;
        text-align: left;
        &:nth-child(1) {
          margin-right: 60px;
        }
      }
    }
  }
  .gap3 {
    height: 32px;
    background: #eee;
    margin: 32px 0 0;
  }
  .sixth {
    background: #fff;
    position: fixed;
    bottom: 0;
    right: 0;
    padding-right: 101px;
    width: 100%;
    height: 84px;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-end;
    // flex-direction: row-reverse;
    align-items: center;
    border-top: 16px solid #eee;
  }
  .sett-money {
    margin-left: 771px;
    margin-right: 15px;
  }
  .sett-rmb {
    color: #1890ff;
    margin-right: 10px;
  }
  .sett-total {
    color: #1890ff;
    font-size: 36px;
    margin-right: 24px;
  }
  .sett-status {
    color: #999999;
    font-size: 14px;
    margin-right: 24px;
  }
  .sett-return {
    border-color: #1890ff;
    color: #1890ff;
  }
  .sett-con {
    margin-right: 101px;
  }
  .con2 {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .con4 {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    span {
      color: #999;
      margin-top: 40px;
    }
  }
  /deep/ .el-textarea__inner{
    font-family: inherit !important;
  }
}
</style>
